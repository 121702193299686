body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table td:first-child,
.table th:first-child {
  position: sticky;
  left: 0;
  background-color: white;
}

/* Login and Register Forms */
/* https://www.w3schools.com/css/css_margin.asp */
/* https://www.freecodecamp.org/news/how-to-build-sign-up-form-with-html-and-css/ */
.login-form {
  width: 340px;
  margin: 48px auto;
  background: #f7f7f7;
  /* https://getcssscan.com/css-box-shadow-examples */
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  padding: 32px;
}

/* Offcanvas menu size */
.offcanvas.offcanvas-end.offcanvas-toggling {
  width: 300px;
}

.offcanvas.offcanvas-end.show {
  width: 300px;
}
